import React, { lazy, Suspense } from 'react';

const LazyNewAccount = lazy(() => import('./NewAccount'));

const NewAccount = props => (
  <Suspense fallback={null}>
    <LazyNewAccount {...props} />
  </Suspense>
);

export default NewAccount;
