import React, { lazy, Suspense } from 'react';

const LazyNewPassword = lazy(() => import('./NewPassword'));

const NewPassword = props => (
  <Suspense fallback={null}>
    <LazyNewPassword {...props} />
  </Suspense>
);

export default NewPassword;
