import React, { lazy, Suspense } from 'react';

const LazyEnterPassword = lazy(() => import('./EnterPassword'));

const EnterPassword = props => (
  <Suspense fallback={null}>
    <LazyEnterPassword {...props} />
  </Suspense>
);

export default EnterPassword;
