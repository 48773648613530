import { Spin } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Suspense } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Test from "../src/components/Test/Test.lazy";
import EmailSent from './pages/Login/EmailSent/EmailSent.lazy';
import EnterEmail from './pages/Login/EnterEmail/EnterEmail.lazy';
import EnterPassword from './pages/Login/EnterPassword/EnterPassword.lazy';
import LoggedOut from './pages/Login/LoggedOut/LoggedOut.lazy';
import LoginError from './pages/Login/LoginError/LoginError.lazy';
import NewAccount from './pages/Login/NewAccount/NewAccount.lazy';
import NewPassword from './pages/Login/NewPassword/NewPassword.lazy';
import Welcome from './pages/Login/Welcome/Welcome.lazy';
import store from './redux/store';
import { AppRoute } from './routing/AppRoute';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<Spin />}>
          <Routes>
            <Route path="/" element={<Navigate replace to="/login/welcome" />} />
            <Route
              path="/login"
              element={<AppRoute protect={false}>
                <Outlet />
              </AppRoute>
              }>
              <Route path="welcome" element={<Welcome />} />
              <Route path="mail" element={<EnterEmail />} />
              <Route path="password" element={<EnterPassword />} />
              <Route path="error" element={<LoginError />} />
              <Route path="emailconfirm" element={<EmailSent />} />
              <Route path="newpassword" element={<NewPassword />} />
              <Route path="signup" element={<NewAccount />} />
              <Route path="logout" element={<LoggedOut />} />
              <Route path="test" element={<Test />} />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </Provider>

  );
}

export default App;
