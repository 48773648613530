import React, { lazy, Suspense } from 'react';

const LazyLoginError = lazy(() => import('./LoginError'));

const LoginError = props => (
  <Suspense fallback={null}>
    <LazyLoginError {...props} />
  </Suspense>
);

export default LoginError;
