import React from 'react';
import styles from './Layout.module.scss';

const Layout = (props) => (
  <div className={styles.Layout}>
          {/* {isNavbar && <Navbar />} */}
          <div className={styles.Layout__body}>
            {props.children}
          </div>
  </div>
);

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
