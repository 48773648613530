import React, { lazy, Suspense } from 'react';

const LazyEmailSent = lazy(() => import('./EmailSent'));

const EmailSent = props => (
  <Suspense fallback={null}>
    <LazyEmailSent {...props} />
  </Suspense>
);

export default EmailSent;
