import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    loginEmail:"",
    loginPassword:""
}

let loginSlice = createSlice({
    name:"login",
    initialState,
    reducers:{
        storeEmail: function(state,action){
            state.loginEmail= action.payload
        },
        storePassword: function(state,action){
            state.loginPassword= action.payload
        }
    }
})

export const {storeEmail,storePassword} = loginSlice.actions;
export default loginSlice.reducer;