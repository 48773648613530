import React, { lazy, Suspense } from 'react';

const LazyEnterEmail = lazy(() => import('./EnterEmail'));

const EnterEmail = props => (
  <Suspense fallback={null}>
    <LazyEnterEmail {...props} />
  </Suspense>
);

export default EnterEmail;
