import React, { lazy, Suspense } from 'react';

const LazyLoggedOut = lazy(() => import('./LoggedOut'));

const LoggedOut = props => (
  <Suspense fallback={null}>
    <LazyLoggedOut {...props} />
  </Suspense>
);

export default LoggedOut;
